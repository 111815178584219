/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to true if you want to use the splash screen.
  useCustomCursor: true, // Change this to false if you want the good'ol cursor
  googleTrackingID: "UA-174238252-2",
};

//Home Page
const greeting = {
  title: "Hello.",
  title2: "khaitruong",
  logo_name: "KhaiTruong",
  nickname: "khaitruong1301 / kaitokido14120",
  full_name: "Trương Tấn Khải",
  subTitle:
    "Full Stack Developer, software/solution architect, Developer Instructor at cybersoft and techx academy . Always learning.",
  resumeLink: "",
  mail: "mailto:khaitruon1301@gmail.com@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/khaitruong1301",
  linkedin: "https://www.facebook.com/kido.kaitou/",
  gmail: "khaitruong1301@gmail.com",
  gitlab: " ",
  facebook: "https://www.facebook.com/kido.kaitou/",
  twitter: "",
  instagram: "https://www.instagram.com/kidokaito/",
};

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using ReactJS and Angular",
        "⚡ Developing mobile applications using Flutter and native Android",
        "⚡ Backend development using NodeJS, ExpressJS and Flask",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "PHP",
          fontAwesomeClassname: "simple-icons:php",
          style: {
            color: "#7377AD",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "simple-icons:java",
          style: {
            color: "#f89820",
          },
        },
        {
          skillName: "Kotlin",
          fontAwesomeClassname: "simple-icons:kotlin",
          style: {
            color: "#5c79df",
          },
        },
        {
          skillName: "Dart",
          fontAwesomeClassname: "simple-icons:dart",
          style: {
            color: "#29B0EE",
          },
        },
        {
          skillName: "Go",
          fontAwesomeClassname: "simple-icons:go",
          style: {
            color: "#00A7D0",
          },
        },
        {
          skillName: "Visual Basic",
          fontAwesomeClassname: "simple-icons:dot-net",
          style: {
            color: "#029FCE",
          },
        },
        {
          skillName: "C#",
          fontAwesomeClassname: "simple-icons:csharp",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "C",
          fontAwesomeClassname: "simple-icons:c",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "C++",
          fontAwesomeClassname: "simple-icons:cplusplus",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NextJS",
          fontAwesomeClassname: "simple-icons:nextdotjs",
          style: {
            color: "#FFFFFF",
            backgroundColor: "#1D1D1D",
            borderRadius: "50%",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#439743",
          },
        },
        {
          skillName: "GraphQL",
          fontAwesomeClassname: "simple-icons:graphql",
          style: {
            color: "#DE33A6",
          },
        },
        {
          skillName: "Android App Development",
          fontAwesomeClassname: "simple-icons:android",
          style: {
            color: "#3DDC84",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },

        {
          skillName: "Wordpress",
          fontAwesomeClassname: "simple-icons:wordpress",
          style: {
            color: "#207297",
          },
        },

        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
      ],
    },

    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Certified AWS Developer Associate and Cloud Practioner",
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Experience hosting and managing web apps on GCP and AWS",
        "⚡ Deploying deep learning models on cloud to use on mobile devices",
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Netlify",
          fontAwesomeClassname: "simple-icons:netlify",
          style: {
            color: "#38AFBB",
          },
        },
        {
          skillName: "Heroku",
          fontAwesomeClassname: "simple-icons:heroku",
          style: {
            color: "#6863A6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
      ],
    },
    {
      title: "Data Science & AI",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Experience of working with Computer Vision and NLP projects",
        "⚡ Certifications by deeplearning.ai and Stanford Online",
        "⚡ Experience with 10+ Projects",
      ],
      softwareSkills: [
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Keras",
          fontAwesomeClassname: "simple-icons:keras",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "PyTorch",
          fontAwesomeClassname: "logos-pytorch",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Pandas",
          fontAwesomeClassname: "simple-icons:pandas",
          style: {
            backgroundColor: "transparent",
            color: "#6E6E6E",
          },
        },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Ho Chi Minh City University of Technology",
      subtitle: "Computer Science ",
      logo_path: "dal.png",
      alt_name: "DAL",
      duration: "2011 - 2015",
      descriptions: [
        "⚡ Proficient in Coding: Highly skilled in programming languages such as Python and Java, with a focus on creating efficient algorithms and software solutions",
        "⚡ Strong Problem-Solving: Demonstrated ability to tackle complex challenges, utilizing logical thinking and innovative approaches to find effective solutions",
        "⚡ Technical Collaboration: Experienced in teamwork within a technical context, effectively communicating ideas and contributing to group projects",
      ],
      website_link: "https://hcmut.edu.vn/",
    },
    // {
    //   title: "SAL Engineering and Technical Institute",
    //   subtitle: "B.Tech. in Computer Engineering",
    //   logo_path: "sal.png",
    //   alt_name: "SETI",
    //   duration: "2017 - 2021",
    //   descriptions: [
    //     "⚡ I have studied core subjects like Data Structures, DBMS, Networking, Security, etc.",
    //     "⚡ I have also completed various online courses for ML/DL, Web Development, Mobile App Development, etc.",
    //     "⚡ I have implemented several projects based on what I've learnt under my Computer Engineering course. ",
    //   ],
    //   website_link: "https://www.sal.edu.in/",
    // },
  ],
};

const certifications = {
  certifications: [
    {
      title: "AWS Developer Associate",
      subtitle: "Amazon Web Services",
      logo_path: "aws.png",
      certificate_link:
        "https://www.credly.com/badges/b12fbece-07f3-47f5-9dda-cd56f49e250d/public_url",
      alt_name: "AWS",
      color_code: "#ffc475",
    },
    {
      title: "AWS Certified Cloud Practioner",
      subtitle: "Amazon Web Services",
      logo_path: "aws.png",
      certificate_link:
        "https://www.credly.com/badges/8b2db191-702d-427c-91aa-0f926be447a6/public_url",
      alt_name: "AWS",
      color_code: "#ffc475",
    },
    {
      title: "Google Summer of Code 2019",
      subtitle: "Google / Sugar Labs",
      logo_path: "google_logo.png",
      certificate_link: " ",
      alt_name: "Google",
      color_code: "#ffc475",
    },
    {
      title: "Google Code-In Student",
      subtitle: "2014-2017",
      logo_path: "google_logo.png",
      certificate_link: " ",
      alt_name: "Google",
      color_code: "#1e70c1",
    },
    {
      title: "Google Summer of Code Mentor",
      subtitle: "2017 / 2018 / 2020",
      logo_path: "google_logo.png",
      certificate_link: " ",
      alt_name: "Google",
      color_code: "#ffbfae",
    },
    {
      title: "Google Code-In Mentor",
      subtitle: "2017 / 2018 / 2019",
      logo_path: "google_logo.png",
      certificate_link: " ",
      alt_name: "Google",
      color_code: "#b190b0",
    },
    {
      title: "Deep Learning Specialization",
      subtitle: "deeplearning.ai",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://coursera.org/share/737a9587023c666b8e6cb303157aaeba",
      alt_name: "deeplearning.ai",
      color_code: "#47A048",
    },
    {
      title: "Sequence Models",
      subtitle: "deeplearning.ai",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/FM5AKEZA9NUY",
      alt_name: "deeplearning.ai",
      color_code: "#F6B808",
    },
    {
      title: "Convolutional Neural Networks",
      subtitle: "deeplearning.ai",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/U8BLDNUT9UUM",
      alt_name: "deeplearning.ai",
      color_code: "#2AAFED",
    },
    {
      title: "Structuring Machine Learning Projects",
      subtitle: "deeplearning.ai",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/YLC25SJQKH3Y",
      alt_name: "deeplearning.ai",
      color_code: "#E2405F",
    },
    {
      title: "Machine Learning",
      subtitle: "deeplearning.ai",
      logo_path: "stanford_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/records/72KY93DT82MP",
      alt_name: "Stanford University",
      color_code: "#8C151599",
    },
    {
      title: "Neural Networks and Deep Learning",
      subtitle: "deeplearning.ai",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/records/25JXRB2RWHRX",
      alt_name: "Google",
      color_code: "#7A7A7A",
    },
    {
      title: "Improving Deep Neural Networks",
      subtitle: "deeplearning.ai",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/records/PKR9M9LQ3JWC",
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
    {
      title: "Android Developer Nanodegree",
      subtitle: "Part of Google India Scholarship Program",
      logo_path: "100.png",
      certificate_link: "https://graduation.udacity.com/confirm/HLE7K5V3",
      alt_name: "Google",
      color_code: "#C5E2EE",
    },
    {
      title: "InOut 4.0 Winner #2",
      subtitle: "2017",
      logo_path: "ino.png",
      certificate_link: " ",
      alt_name: "InOut",
      color_code: "#fffbf3",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I've completed two internships. I've mostly done projects on my own and I am actively looking for internships. I love organizing workshops to share my knowledge with others.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Solution architect",
          company: "TechX Academy",
          company_url: "https://techx.edu.vn/",
          logo_path: "https://techx.edu.vn/img/logo/logo-cyber.svg",
          duration: "May 2023 - Ongoing",
          location: "2bis Nguyễn Thị Minh Khai, District 1, Tp.HCM",
          description:
            "My role involves overseeing human training and providing directions for the development of systems at Tech X. I manage training programs for individuals and guide the company's technological advancement initiatives.",
          color: "#f10000",
        },
        {
          title: "Software Architect, FullStack Developer",
          company: "Cyberlearn Academy",
          company_url: "https://cyberlearn.vn/",
          logo_path: "https://cyberlearn.vn/wp-content/uploads/2020/03/cyberlearn-min-new-opt2.png",
          duration: "2019 - Ongoing",
          location: "122 Cao Thắng, District 1, Tp.HCM",
          description:
            "At CyberLearn, my responsibility is to collaborate with adjunct instructors, facilitating the creation of courses and providing guidance for their instructional deployment on the system.",
          color: "#2962FF",
        },
        {
          title: "Software Architect",
          company: "Cybersoft Academy",
          company_url: "https://cybersoft.edu.vn/",
          logo_path: "https://cybersoft.edu.vn/wp-content/uploads/2022/10/cyberlogo-white.png",
          duration: "2016 - Ongoing",
          location: "122 Cao Thắng, District 1, Tp.HCM",
          description:
            "At Cybersoft Academy, I am in charge of creating compelling educational materials and overseeing the organization's training efforts. This entails designing content that effectively communicates intricate ideas and managing the execution and coordination of various training programs. My goal is to guarantee that our students gain valuable insights and skills in the rapidly changing technology landscape through well-crafted content and efficient training management.",
          color: "#2962FF",
        },
        
      ],
    },
    {
      title: "History",
      experiences: [
        {
          title: "Web developer",
          company: "The software solution company Cybersoft",
          company_url: "https://cybersoft.edu.vn/",
          logo_path: "https://cybersoft.edu.vn/wp-content/uploads/2022/10/cyberlogo-white.png",
          duration: "2016 - 2019",
          location: "459 Sư Vạn Hạnh District 10, Tp.HCM",
          description:
            "Internship task was to make a native Android apI took part in outsourcing projects for different companies, assuming roles that ranged from developer to software architect. These projects encompassed tasks such as warehouse management for Vivi Inventory Management Company, creating a securities trading system for HOSE (Ho Chi Minh City Stock Exchange), handling legal document management for Coteccons Law, managing a chain of dental clinics for VNDental, and contributing to the online teaching platform MyClass.vn.lication.",
          color: "#0071C5",
        },
        {
          title: "Web Developer",
          company: "Mekomed clinic",
          company_url: "https://mekomed.vn/",
          logo_path: "https://mekomed.vn/upload/hinhanh/logo-1366.jpg",
          duration: "2015 - 2016",
          location: "",
          description: "I was responsible for designing and deploying software systems for Mekomed Clinic, streamlining healthcare operations and management processes. This role involved creating user-friendly interfaces and ensuring seamless integration of various functionalities to enhance the clinic's overall efficiency.",
          color: "#56A4D3",
        },
        {
          title: "Project Team Member",
          company: "EVN",
          logo_path: "./imgevn.png",
          duration: "2015",
          location: "",
          description: "I was involved in a project focused on creating a Geographic Information System (GIS) model for the electrical grid of Ho Chi Minh City. In this role, I collaborated with a multidisciplinary team to gather and analyze geospatial data, design the grid layout, and implement the GIS model to enhance the city's electricity distribution infrastructure",
         
          color: "#800000",
        },
      ],
    },
    
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Data Science projects. Below are some of my projects. Note that not all of the mentioned projects are on GitHub yet.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "/avatar22.jpeg",
    description:
      "You can contact me at the places mentioned below. I will try to get back to you as fast as I can. ",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I don't blog frequently but when I do something awesome, I do try my best to write a blog about it.",
    link: "https://www.facebook.com/kido.kaitou/",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [
    {
      name: "techx.edu.vn",
      url: "#",
      description: "A personalized training system for individual learners, tailored to guide them in their specialized programming field.",
      languages: [
        {
          name: ".NET",
          iconifyClass: "logos-dotnet",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-docker",
        },
        {
          name: "nextjs",
          iconifyClass: "logos-nextjs",
        },
        {
          name: "Typescript",
          iconifyClass: "logos:typescript-icon",
        },
        {
          name: "SQL Server",
          iconifyClass: "devicon:sqldeveloper",
        },
        
      ],
    },
    {
      name: "Edubiz",
      url: "#",
      description: "A coaching platform catering to educators and learners, empowering educators to create impactful lessons and guide progress, and offering learners interactive content and direct engagement for effective learning experiences.",
      languages: [
        {
          name: ".NET",
          iconifyClass: "logos-dotnet",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-docker",
        },
        {
          name: "nextjs",
          iconifyClass: "logos-nextjs",
        },
        {
          name: "Typescript",
          iconifyClass: "logos:typescript-icon",
        },
        {
          name: "SQL Server",
          iconifyClass: "devicon:sqldeveloper",
        },
        
      ],
    },
    {
      name: "cybersoft.edu.vn",
      url: "#",
      description: "A short-term vocational training system for web programming that enables graduates to enter the workforce upon completion.",
      languages: [
        {
          name: ".NET",
          iconifyClass: "logos-dotnet",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-docker",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-nextjs",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos:typescript-icon",
        },
        {
          name: "SQL Server",
          iconifyClass: "devicon:sqldeveloper",
        },
      ],
    },
    {
      name: "cyberlearn.edu.vn",
      url: "#",
      description: "A specialized training system that breaks down in-depth subject knowledge into small, focused modules, designed for individuals seeking to enhance their expertise or acquire vocational skills for employment purposes.",
      languages: [
        {
          name: ".NET",
          iconifyClass: "logos-dotnet",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "NextJS",
          iconifyClass: "logos-nextjs",
        },
        {
          name: "Typescript",
          iconifyClass: "logos:typescript-icon",
        },
        {
          name: "SQL Server",
          iconifyClass: "devicon:sqldeveloper",
        },
      ],
    },

    {
      name: "clinic.mekomed.vn",
      url: "#",
      description: "A management system for Mekomed clinics, encompassing diverse multi-disciplinary healthcare fields within the medical industry.",
      languages: [
        {
          name: ".NET core",
          iconifyClass: "devicon:dotnetcore",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "Reacjs",
          iconifyClass: "logos-react",
        },
        {
          name: "docker",
          iconifyClass: "devicon:docker-wordmark",
        },
        {
          name: "typescript",
          iconifyClass: "devicon:typescript",
        },
      ],
    },
    {
      name: "Managing Coteccons Law Documents.",
      url: "#",
      description:
        "A document management system for legal documents within Coteccons company.",
      languages: [
        {
          name: "Flutter",
          iconifyClass: "logos-flutter",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "Reacjs",
          iconifyClass: "logos-react",
        },
        {
          name: "docker",
          iconifyClass: "devicon:docker-wordmark",
        },
        {
          name: "typescript",
          iconifyClass: "devicon:typescript",
        },
        {
          name: "SQL Server",
          iconifyClass: "devicon:sqldeveloper",
        },
      ]
    },
    {
      name: "VN Dental",
      url: "#",
      description:
        "A dental chain management system for VNDental, encompassing dental operations and employee management tasks.",
      languages: [
        {
          name: "Flutter",
          iconifyClass: "logos-flutter",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "Reacjs",
          iconifyClass: "logos-react",
        },
        {
          name: "docker",
          iconifyClass: "devicon:docker-wordmark",
        },
        {
          name: "typescript",
          iconifyClass: "devicon:typescript",
        },
        {
          name: "SQL Server",
          iconifyClass: "devicon:sqldeveloper",
        },
      ]
    },
    {
      name: "Web Hose",
      url: "#",
      description:
        "Maintained and developed various features for the web management system of HOSE (Ho Chi Minh City Stock Exchange).",
      languages: [
       

        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      
        {
          name: "docker",
          iconifyClass: "devicon:docker-wordmark",
        },
        {
          name: "typescript",
          iconifyClass: "devicon:typescript",
        },
        {
          name: "SQL Server",
          iconifyClass: "devicon:sqldeveloper",
        },
        
      ]
      
    },
    {
      name: "Many other outsourcing projects.",
      url: "#",
      description:
        "In a role involving analyzing, evaluating, and determining tech strategies for multiple teams handling outsourcing projects concurrently. Also, I lead these teams to achieve successful project outcomes.",
      languages: [
        {
          name: "Flutter",
          iconifyClass: "logos-flutter",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "Reacjs",
          iconifyClass: "logos-react",
        },
        
        {
          name: "typescript",
          iconifyClass: "devicon:typescript",
        },
        {
          name: "SQL Server",
          iconifyClass: "devicon:sqldeveloper",
        },
        {
          name: "Python",
          iconifyClass: "devicon:python",
        },
        {
          name: "Angular",
          iconifyClass: "devicon:angular",
        },
        {
          name: "Wordpress",
          iconifyClass: "devicon:wordpress",
        },
      ]
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
